import React from "react";
import { Link } from 'react-router-dom';

export const EditLink = (props) => {

    return (
        props.condition ?
            <Link
                className='tableIconBtn'
                to={props.to}>
                {props.children}
            </Link>
            :
            <button
                className='tableIconBtn'
                onClick={props.conditionAction}>
                {props.children}
            </button>
    );
};